import React from "react";
import './style.css';

class Image extends React.Component {
  render() {
      return <div className={`image ${this.props.imageType}`}>
          <div className={'ratio'}>
              <div id={this.props.imageName} className={`mask ${this.props.imagePosition}`}>
                  <span role={'img'} aria-label={this.props.imageDescription} />
              </div>
          </div>
      </div>
  }
}

export default Image;
