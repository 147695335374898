import React from "react";
import './style.css';
import Image from '../../atoms/Image';
//import Modal from '../../molecules/Modal';
class Card extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isActive: true,
        }
        this.cardClick = this.cardClick.bind(this);
    }
    cardClick(){
        console.log('clicked.')
        this.setState(state => ({
            isActive: !state.isActive,
        }))
    }
    render() {
        return <div id={`card-${this.props.cardInfo.cardID}`} className={`card card-${this.props.cardInfo.cardType}`}>
          {this.state.isActive ? <div onClick={this.cardClick}><Image imageType={this.props.cardInfo.cardImageInfo[0].imageType} imageName={this.props.cardInfo.cardImageName} imageDescription={this.props.cardInfo.cardImageInfo[0].imageDescription} imagePosition={this.props.cardInfo.cardImageInfo[0].imagePosition} /></div> : <div className={'up-card'}onClick={this.cardClick}>
              <div className={`up-card-info ${this.props.cardInfo.cardColor}`}>
                  <h3><a href={this.props.cardInfo.cardUrl}>{this.props.cardInfo.cardText.cardTitle}</a></h3>
                  <p>{this.props.cardInfo.cardText.cardBody}</p>
                  <p className={'ty'}>Special thank you:<br />{this.props.cardInfo.cardText.cardAuthor}</p>
              </div>
              <Image imageType={this.props.cardInfo.cardImageInfo[0].imageType} imageName={this.props.cardInfo.cardImageName} imageDescription={this.props.cardInfo.cardImageInfo[0].imageDescription} imagePosition={this.props.cardInfo.cardImageInfo[0].imagePosition} />
            </div>   
          }
      </div>

  }
}
export default Card;