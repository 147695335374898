import React from "react";
import './style.css';
class Footer extends React.Component {
    render() {
      return <div className={'footer'}>
        <div className={'rapper'}>
          <p>&copy; Dale Carter 2021. All rights reserved.</p>
        </div>
      </div>
  }
}
export default Footer;