import React from "react";
import './style.css';
class Navigation extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isToggleOn: false,
            isOffset: false,
        };
        this.naviClick = this.naviClick.bind(this);
    }
    checkOffset = e => {
        if (window.scrollY > 160) {
          this.setState({isOffset: true})
        } else {
          this.setState({isOffset: false})
        }
      }
    
      componentDidMount() {
        window.addEventListener('scroll', this.checkOffset)
      }
    naviClick(){
        this.setState(state => ({
            isToggleOn: !state.isToggleOn
        }))
    }
    brandClick(){
        window.location = '/';
    }
    navItems = this.props.navItems;
    mapNav = this.navItems.map((a) =>
        <li key={a.navID} id={a.navID}>
            {
                a.isExternal === true ?
                    <a href={a.navContent.navURL} className={`icn icn-${a.navContent.navIcon}`}><span>{a.navContent.navLabel}</span></a>
                    :
                    <a href={a.navContent.navURL} className={`icn icn-${a.navContent.navIcon}`}><span>{a.navContent.navLabel}</span></a>
            }
        </li>
    )
    render() {
      return <nav className={this.state.isOffset ? 'navi move' : 'navi'} role={'navigation'} >
          <div className={'navi-container'}>
            <div className={`brand ${this.props.menuBG}`}>
                {
                    this.props.hasLogo?
                        <div className={'nav-brand'} onClick={this.brandClick}><div className={'icn-brand'}></div></div>
                        :
                        <div className={'nav-brand'} onClick={this.brandClick}><div className={'icn-brand'}></div><div className={'text-brand'}>{this.props.brandName}</div></div>
                }
            </div>
            <div className={'menu'}>
              <div className={'nav-menu'}>
                  <div className={this.state.isToggleOn ? 'nav-menu-btn open' : 'nav-menu-btn closed'} onClick={this.naviClick}></div>
                  <ul className={this.state.isToggleOn ? 'nav-menu-items shown' : 'nav-menu-items hidden'}>
                      {this.mapNav}
                  </ul>
              </div>
          </div>
          </div>
      </nav>
  }
}
export default Navigation;