import React from 'react';
import './App.css';
import Navigation from './components/organisms/Navigation';
import Card from './components/organisms/Card';
import Footer from './components/molecules/Footer';
const siteNav = [
  {
    navID: '1',
    isExternal : true,
    navContent : {
      'navLabel' : 'LinkedIn',
      'navURL' : 'https://www.linkedin.com/in/cartleader/',
      'navIcon' : 'linkedin'
    }
  },
  {
    navID: '2',
    isExternal : true,
    navContent : {
      'navLabel' : 'Github',
      'navURL' : 'https://github.com/Cartleader',
      'navIcon' : 'github'
    }
  },
  {
    navID: '3',
    isExternal : true,
    navContent : {
      'navLabel' : 'Flickr',
      'navURL' : 'https://www.flickr.com/photos/dalecarter/',
      'navIcon' : 'flickr'
    }
  },
  {
    navID: '4',
    isExternal : true,
    navContent : {
      'navLabel' : 'IMDB',
      'navURL' : 'https://www.imdb.com/name/nm9326341/',
      'navIcon' : 'imdb'
    }
  },
]
const card = [
  {
    cardID: 1,
    cardType: 'website',
    cardColor: 'blue',
    cardText: {
      cardTitle: 'ATB Financial',
      cardAuthor: 'Richard Giraud, Greg Mah and Meg Kendall',
      cardBody: 'FED, Handlebars, Javascript, Vue.js, Bootstrap, Atomic Design, CMS Integration.'
    },
    cardUrl: 'https://atb.com',
    cardImageName: 'atb-financial-website',
    cardImageInfo: [
      {
        imageDescription: 'The ATB Financial website, mobile first.',
        imageType: 'mobile',
        imagePosition: 'top-left'
      }
    ]
  },
  {
    cardID: 2,
    cardType: 'website',
    cardColor: 'black',
    cardText: {
      cardTitle: 'ATB BoostR',
      cardAuthor: 'Amy Zheng, Carly Wells, Katipult',
      cardBody: 'Full Stack Dev, UI, Handlebars, Javascript, Vue.js, Bootstrap, Atomic Design, CMS Integration.'
    },
    cardUrl: 'https://atbboostr.ca',
    cardImageName: 'atb-boostr',
    cardImageInfo: [
      {
        imageDescription: 'The ATB BoostR website.',
        imageType: 'mobile',
        imagePosition: 'top-left'
      }
    ]
  },
  {
    cardID: 3,
    cardType: 'website',
    cardColor: 'red',
    cardText: {
      cardTitle: 'ATB Capital Markets',
      cardAuthor: 'Richard Giraud and Brenda Hanchar',
      cardBody: 'Full Stack Dev, PHP, Laravel, Backpack, Vue.js, Responsive Design, CRM and CMS Integration'
    },
    cardUrl: 'https://atbcapitalmarkets.com/',
    cardImageName: 'atb-capital-markets',
    cardImageInfo: [
      {
        imageDescription: 'The ATB Capital Markets website.',
        imageType: 'mobile',
        imagePosition: 'top-left'
      },
    ]
  },
  {
    cardID: 4,
    cardType: 'website',
    cardColor: 'orange',
    cardText: {
      cardTitle: 'ATB Listens',
      cardAuthor: 'Jesse Yuen',
      cardBody: 'Full Stack Development, Handlebars, Javascript, Vue.js, NGINX, Atomic Design'
    },
    cardUrl: 'https://why.atb.com',
    cardImageName: 'atb-listens',
    cardImageInfo: [
      {
        imageDescription: 'The ATB Listens website.',
        imageType: 'mobile',
        imagePosition: 'top-left'
      }
    ]
  },
  {
    cardID: 9,
    cardType: 'website',
    cardColor: 'green',
    cardText: {
      cardTitle: 'Timebox',
      cardBody: 'Acting and Producing',
      cardAuthor: 'Daelan Wood, Tate Young, Geoff Kramer',
    },
    cardUrl: 'https://gem.cbc.ca/media/media/canadian-reflections/timebox/38e815a-00fb11579cf',
    cardImageName: 'timebox',
    cardImageInfo: [
      {
        imageDescription: 'Timebox is a film by Daelan Wood.',
        imageType: 'mobile',
        imagePosition: 'x-37'
      }
    ]
  },
  {
    cardID: 10,
    cardType: 'website',
    cardColor: 'blue',
    cardText: {
      cardTitle: 'Light the Bridge',
      cardBody: 'Full Stack Dev, ASP.NET, MVC, CMS, FED, jQuery, SQL and SVG',
      cardAuthor: 'Eric Franck, Shane Ricketts and Peggy Garrity'
    },
    cardUrl: 'https://www.lightthebridge.ca/findyourbulb/',
    cardImageName: 'light-the-bridge',
    cardImageInfo: [
      {
        imageDescription: 'The Light the Bridge Wwebsite.',
        imageType: 'mobile',
        imagePosition: 'top-left'
      }
    ]
  },
  {
    cardID: 11,
    cardType: 'website',
    cardColor: 'black',
    cardText: {
      cardTitle: 'Science of the Six Pack',
      cardBody: 'App development, Vue.js, SCSS, MySql, Web Services, Activation',
      cardAuthor: 'Terry Andryo, Tara Tanchak, Richard Giraud and Vance Yung'
    },
    cardImageName: 'atb-sotsp',
    cardImageInfo: [
      {
        imageDescription: 'The Science of the Six Pack Activation App',
        imageType: 'mobile',
        imagePosition: 'top-left'
      }
    ]
  },
  {
    cardID: 12,
    cardType: 'website',
    cardColor: 'red',
    cardText: {
      cardTitle: 'Arts Awards',
      cardBody: 'Full Stack Dev, ASP.NET, SQL, MVC, Design, UX, UI, Analytics, Personalization',
      cardAuthor: 'Eric Rice and Wei Yew'
    },
    cardUrl: 'https://artsawards.ca/',
    cardImageName: 'infocalypse-lgaaa',
    cardImageInfo: [
      {
        imageDescription: 'The Lieutenant Governor of Alberta Arts Awards Foundation',
        imageType: 'mobile',
        imagePosition: 'x-32'
      }
    ]
  },
  {
    cardID: 5,
    cardType: 'website',
    cardColor: 'orange',
    cardText: {
      cardTitle: 'ATB 2020 Annual Report',
      cardAuthor: 'Faith Farthing, Richard Giraud, Eldon Kymson and Devin Yim',
      cardBody: 'Full Stack Dev, Atomic Design, Vue.js, Google Docs, Google Sheets, API Integration'
    },
    cardUrl: 'https://annualreport.atb.com',
    cardImageName: 'atb-financial-annual-report-2020',
    cardImageInfo: [
      {
        imageDescription: 'The ATB Financial Annual Report for the year 2020.',
        imageType: 'mobile',
        imagePosition: 'top-left'
      },
    ]
  },
  {
    cardID: 6,
    cardType: 'website',
    cardColor: 'green',
    cardText: {
      cardTitle: 'ATB 2019 Annual Report',
      cardAuthor: 'Faith Farthing, Richard Giraud and Tikina Gordey',
      cardBody: 'Full Stack Dev, Atomic Design, Vue.js, Google Docs, Google Sheets, API Integration'
    },
    cardUrl: 'https://annualreport-2019.atb.com',
    cardImageName: 'atb-financial-annual-report-2019',
    cardImageInfo: [
      {
        imageDescription: 'The ATB Financial Annual Report for the year 2019.',
        imageType: 'mobile',
        imagePosition: 'top-left'
      }
    ] 
  },
  {
    cardID: 7,
    cardType: 'website',
    cardColor: 'blue',
    cardText: {
      cardTitle: 'ATB 2018 Annual Report',
      cardAuthor: 'Faith Farthing, Jennifer Mendoza and Richard Giraud',
      cardBody: 'Full Stack Dev, UX, UI, Vue.js, SCSS, Google Docs, Google Sheets, API Integration'
    },
    cardUrl: 'https://annualreport-2018.atb.com',
    cardImageName: 'atb-financial-annual-report-2018',
    cardImageInfo: [
      {
        imageDescription: 'The ATB Financial Annual Report for the year 2018.',
        imageType: 'mobile',
        imagePosition: 'top-left'
      }
    ]
  },
  {
    cardID: 8,
    cardType: 'website',
    cardColor: 'black',
    cardText: {
      cardTitle: 'ATB 2018 CSR',
      cardAuthor: 'Holly Regel, Sandra Huculak, Kathy Arychuk and Tikina Gordey',
      cardBody: 'Full Stack Dev, UX, UI, Vue.js, SCSS, SVG Animation, API Integration'
    },
    cardUrl: 'https://csr.atb.com/',
    cardImageName: 'atb-csr-2018',
    cardImageInfo: [
      {
        imageDescription: 'The ATB Financial Corporate Social Responsibility Report for the year 2018.',
        imageType: 'mobile',
        imagePosition: 'top-left'
      }
    ]
  },
  {
    cardID: 13,
    cardType: 'website',
    cardColor: 'red',
    cardText: {
      cardTitle: 'SortItOut!',
      cardBody: 'Web development, UX, UI, Education, Science Cirriculm',
      cardAuthor: 'John Ternan and Eric Rice'
    },
    cardImageName: 'sort-it-out',
    cardImageInfo: [
      {
        imageDescription: 'SortItOut! is a package of resources for Alberta students and educators about waste management.',
        imageType: 'mobile',
        imagePosition: 'x-16'
      }
    ]
  },
  {
    cardID: 14,
    cardType: 'website',
    cardColor: 'orange',
    cardText: {
      cardTitle: 'ATB Adventurers League',
      cardBody: 'Presentation, Leadership, Disruption',
      cardAuthor: 'Neil Graham, Lillian Lam and Kelsie Badry'
    },
    cardImageName: 'atb-adventurers-league',
    cardImageInfo: [
      {
        imageDescription: 'The quarterly meeting for ATB Financial and their department of Brand.',
        imageType: 'mobile',
        imagePosition: 'dead-centre'
      }
    ]
  },
  {
    cardID: 15,
    cardType: 'website',
    cardColor: 'green',
    cardText: {
      cardTitle: 'Lorne Rubis',
      cardBody: 'Full Stack Dev, LAMP, Wordpress, MySql, Plugins, Themes',
      cardAuthor: 'Alana Dawes, Christina Luo and Lorne Rubis'
    },
    cardImageName: 'rubis',
    cardImageInfo: [
      {
        imageDescription: 'The wordpress theme for Lorne Rubis',
        imageType: 'mobile',
        imagePosition: 'top-left'
      }
    ]
  },
  {
    cardID: 16,
    cardType: 'website',
    cardColor: 'blue',
    cardText: {
      cardTitle: 'The Chuckwagon Race',
      cardBody: 'Full Stack Dev, Javascript, jQuery, Visualizations, HTML, SCSS',
      cardAuthor: 'Vance Yung, Aminah Syed and Glen Kubish'
    },
    cardImageName: 'chuckwagon',
    cardImageInfo: [
      {
        imageDescription: 'The Chuckwagon Race with COdy McCurragh',
        imageType: 'mobile',
        imagePosition: 'top-left'
      }
    ]
  },
]
class App extends React.Component{
  render(){
    const mappedNav = siteNav.map(params =>{
      return params
    });
    const cards = card.map((b, index) =>
      <Card key={b.cardID} cardInfo= {card[index]} />
    );
    return <div className={'dale-carter'}>
    <Navigation
          ID={'123'}
          menuBg={'navi'}
          brandName={'Dale Carter'}
          hasLogo={true}
          navItems={mappedNav}
      />
      <div className={'rapper'}>
        <div className={'text-intro'}>
      <h1>Hello.</h1><h5>I'm a father, husband, son, brother, cipher and a mild advocate of the wild hunt. I garden people, plans, processes, pipelines, and plants.</h5><h5>This is a collection of some of the work i've enjoyed doing and things i've been a part of, mostly at 72dpi.</h5><h5>Please enjoy.</h5>
      </div>
    </div>
    <div className={'rapper fourths gapped'}>
      {cards}
    </div>
    <Footer />
  </div>
  }
}
export default App;